import React, { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

// const faqs = [
//   {
//     question: "Operations",
//     answer:
//       "Our team is always growing. Please check again in the future.",
//   },
//   {
//     question: "Business/Product Development",
//     answer:
//       "Our team is always growing. Please check again in the future.",
//   },
//   {
//     question: "Programming",
//     answer:
//       "Our team is always growing. Please check again in the future.",
//   },
//   {
//     question: "Information Technology",
//     answer:
//       "Our team is always growing. Please check again in the future.",
//   },
//   {
//     question: "Marketing",
//     answer:
//       "Our team is always growing. Please check again in the future.",
//   },
//   {
//     question: "Data Sciences",
//     answer:
//       "Our team is always growing. Please check again in the future.",
//   },
// ]

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

function Careers() {
  return (
    <div className="pb-20 font-poppins bg-white mt-20">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 mt-32">
        <div className="max-w-3xl mx-auto">
          <div className="mb-5">
            <h2 className="text-center text-3xl font-extrabold text-blue-500 sm:text-4xl">Join Our Team</h2>
            <hr className="my-6 border-golden-100" />
            <p className="text-lg text-gray-900 mt-2">LENDEM Solutions believes our employees are at the heart of our success. As such, we strive to provide an environment that enables our employees to stretch their boundaries, follow their curiosity, and expand their expertise.</p>
            <p className="text-lg text-gray-900 mt-2">As our company grows, we want our employees to grow with us. Throughout all our departments, the foundation of our commitment to our customers begins with our commitment to our employees. Through hiring, training, compensation, benefits, and support, we endeavor to create a culture that supports diverse experiences, challenging voices, and forward-thinking talent.</p>   
          </div>
          <div className="mt-5">
            <h2 className="text-center text-3xl font-extrabold text-blue-500 sm:text-4xl pt-5">Open Positions</h2>
            <hr className="my-6 border-golden-100" />
            <div className="flex bg-gray-100 justify-between w-[100%] p-3 mt-5 rounded-md shadow-sm">
              <p className="text-lg font-semibold text-blue-500">No Current Openings</p>
              {/*<p><a className="cursor-pointer" href="assets/senior_data_scientist_job_opening.pdf" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="w-6 h-6 text-golden-100" icon={faFilePdf} aria-hidden="true" /></a></p>*/}
            </div>
          </div>
          {/* <div>
          <a href="https://www.indeed.com/cmp/Lendem-Solutions" className="bg-blue-500 text-white transition ease-in-out delay-120 duration-300 hover:bg-white hover:text-blue-500 border border-transparent hover:border-blue-500 py-3 px-6 font-semibold rounded-lg shadow-md inline-block mt-5">Click Here</a>
          </div> */}
          {/* <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <Fragment>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <FontAwesomeIcon icon={faChevronDown}
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </Fragment>
                )}
              </Disclosure>
            ))}
          </dl> */}
        </div>
      </div>
    </div>
  );
}

export default Careers;
